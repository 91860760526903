<!-- src/views/CompanyRegistration.vue -->
<template>
  <div>
    <h3 class="border-bottom mb-3">Company Registration {{ clientId }}</h3>
    <form @submit.prevent="submitCompany" class="radius-0">
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <label for="companyName" class="form-label">Company Name</label>
          <input type="text" class="form-control" id="companyName" v-model="company.companyName" required />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label for="orgNr" class="form-label">Org nr</label>
          <input type="text" class="form-control" id="orgNr" v-model="company.orgNr" required />
        </div>
      </div>
      <div class="mb-3">
        <label for="address" class="form-label">Address</label>
        <input type="text" class="form-control" id="address" v-model="company.address" required />
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <label for="city" class="form-label">Zip</label>
          <input type="text" class="form-control" id="zip" v-model="company.zip" required />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label for="city" class="form-label">City</label>
          <input type="text" class="form-control" id="city" v-model="company.city" required />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <label for="contact" class="form-label">E-Mail</label>
          <input type="text" class="form-control" id="contact" v-model="company.contact" required />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label for="contact" class="form-label">Phone</label>
          <input type="text" class="form-control" id="phone" v-model="company.phone" />
        </div>
      </div>
      <div class="mb-3">
        <label for="notes" class="form-label">Notes</label>
        <textarea rows="4" class="form-control" id="notes" v-model="company.notes"></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Register</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { useStorage } from '@vueuse/core';
import { generateUniqueId } from '../utils';
const clientId = useStorage('clientId')
clientId.value = null;

const backendUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'CompanyRegistration',
  data() {
    return {
      company: {
        clientId: '',
        companyName: '',
        orgNr: '',
        address: '',
        zip: '',
        city: '',
        contact: '',
        phone: '',
        notes: '',
      },
      clientId
    };
  },
  methods: {
    async submitCompany() {
      try {
        clientId.value = generateUniqueId();
        useStorage('client-id').value = clientId.value;
        console.log('New ClientId', clientId.value);
        
        this.company.clientId = clientId.value;

        const payload = {
          ...this.company,
        };

        await axios.post(backendUrl + '/api/register-company', payload);
        
        this.$router.push('/register-product');
      } catch (error) {
        console.error(error);
        alert('Error registering company.');
      }
    },
  },
};
</script>