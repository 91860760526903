<!-- src/views/Success.vue -->
<template>
    <div class="d-flex justify-content-around">
    <div class="card border-0" style="width: 23rem;">
        <div class="d-flex justify-content-around">
            <img class="" style="max-width:200px;" src="/success.png" alt="Success" />
        </div>
    <div class="card-body text-center p-3">
      <h3 class="mb-3">Registration Successful!</h3>
      <h5>Your ID is: <strong class="text-danger">{{ clientId }}</strong></h5>
      <p>Please save your ID for future registrations</p>
      <div class="my-3">
          <a href="/register-product" class="btn btn-primary">Register another product</a>
      </div>
    </div>
</div>
</div>
  </template>
  
<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router'

const route = useRoute()
const clientId = ref('');

// get clientId from router param
clientId.value = route.params.clientId;

</script>
