

export const generateUniqueId = () => {
    // Helper function to generate three random characters
    function randomSegment() {
        return Math.random().toString(36).substring(2, 5); // Generates a random string of 3 characters
    }
    return `${randomSegment()}-${randomSegment()}-${randomSegment()}`.toUpperCase();
}

export const isIDValid = (id) => {
    return /^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}$/.test(id);
}