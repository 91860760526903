<!-- src/App.vue -->
<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand py-0" href="#">
          <img src="/EKOion_RGB_neg.png" alt="" width="100" class="d-inline-block align-text-top" />

        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <!--
            <li class="nav-item" v-if="clientId">
              <router-link class="nav-link" to="/register-company">Register Company</router-link>
            </li>
            -->
            <li class="nav-item" v-if="clientId">
              <router-link class="nav-link" to="/register-product">Register Product</router-link>
            </li>
            <li class="nav-item" v-if="clientId">
              <router-link class="nav-link" to="/my-registrations">My Registrations</router-link>
            </li>
            <li class="nav-item" v-if="clientId == 'AAA-BBB-CCC'">
              <router-link class="nav-link" to="/all-registrations">All Registrations</router-link>
            </li>
          </ul>
        </div>

      </div>
    </nav>
    <div class="container mt-4">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const clientId = ref('');

onMounted(() => {
  clientId.value = localStorage.getItem('client-id') ?? null;
});





</script>


<style>
/* Add any global styles if needed */
</style>
