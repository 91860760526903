// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import CompanyRegistration from '../views/CompanyRegistration.vue';
import ProductRegistration from '../views/ProductRegistration.vue';
import Success from '../views/Success.vue';
import AllRegistrations from '../views/AllRegistrations.vue'; // Import the new component
import MyRegistrations from '../views/MyRegistrations.vue'; // Import the new component
import Test from '../views/Test.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/register-company',
    name: 'RegisterCompany',
    component: CompanyRegistration,
  },
  {
    path: '/register-product/:clientid?',
    name: 'RegisterProduct',
    component: ProductRegistration,
  },
  {
    path: '/success/:clientid',
    name: 'Success',
    component: Success,
  },
  {
    path: '/all-registrations',
    name: 'AllRegistrations',
    component: AllRegistrations, 
  },
  {
    path: '/my-registrations/:clientId?',
    name: 'MyRegistrations',
    component: MyRegistrations,     
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
