<!-- src/views/ProductRegistration.vue -->
<style scoped>
.text-small {
    font-size: 0.8rem;
}

</style>
<template>
    <div>
        <div class="d-flex justify-content-between border-bottom mb-2">
            <h3 class="">Product Registration</h3>
            <h4>{{ company?.companyName }}</h4>
        </div>
        <form id="productForm" @submit.prevent="submitProduct" v-if="isLoaded">
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="serialNr" class="form-label">Serial nr</label>
                    <input type="text" class="form-control" id="serialNr" v-model="product.serialNr" required />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="model" class="form-label">Product</label>
                    <select class="form-select" id="model" v-model="product.model" required>
                        <option value="" disabled>Select product</option>
                        <option v-for="model in models" :key="model" :value="model">{{ model }}</option>
                    </select>

                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="purchaseDate" class="form-label">Purchase Date</label>
                    <input type="date" class="form-control" id="purchaseDate" v-model="product.purchaseDate" required />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="installationDate" class="form-label">Installation Date</label>
                    <input type="date" class="form-control" id="installationDate" v-model="product.installationDate" required />
                </div>
            </div>
            <div class="mb-3">
                <label for="address" class="form-label">Address</label>
                <input type="text" class="form-control" id="address" v-model="product.address" required />
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="city" class="form-label">Zip</label>
                    <input type="text" class="form-control" id="zip" v-model="product.zip" required />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="city" class="form-label">City</label>
                    <input type="text" class="form-control" id="city" v-model="product.city" required />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label for="contact" class="form-label">E-Mail</label>
                    <input type="text" class="form-control" id="contact" v-model="product.contact" />

                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="phone" class="form-label">Phone</label>
                    <input type="text" class="form-control" id="phone" v-model="product.phone" />
                </div>
            </div>
            <div class="mb-3">
                <label for="notes" class="form-label">Notes</label>
                <textarea rows="4" class="form-control" id="notes" v-model="product.notes"></textarea>
            </div>

            <button type="submit" class="btn btn-primary">Register</button>
        </form>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
//import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator';


const router = useRouter()
const route = useRoute()

const backendUrl = process.env.VUE_APP_BACKEND_URL;
const isLoaded = ref(false);
const clientId = ref('');
const company = ref(null);
const models = ref([
    'Lixo 100',
    'Lixo 200',
    'Lixo 300'
]);

const rules = {
  serialNr: {
    type: 'string',
    min: 11,
    max: 11,
    required: true,
  },
  model: {
    type: 'string',
    required: true,
  },
  contact: [
    {
      type: 'email',
      required: true,
    },
  ],
}

const product = ref({
    serialNr: '',
    model: '',
    purchaseDate: '',
    installationDate: '',
    address: '',
    city: '',
    zip: '',
    contact: '',
    phone: '',
    notes: '',
});

//const { pass, isFinished, errorFields } = useAsyncValidator(product, rules)

const validateFields = () => {
    // trigger form validation and return boolean value
    const form = document.getElementById('productForm');
    form.reportValidity();
    if (!form.checkValidity()) {
        throw new Error("Form validation failed.");
    }
    

}

onMounted(async () => {
    clientId.value = route.params.clientId;
    if (!clientId.value) {
        clientId.value = localStorage.getItem('client-id');
    }
    if (!clientId.value) {
        router.push('/');
    }
    console.log('Mounted clientId:', clientId.value);

    company.value = await getCompany();

    isLoaded.value = true;
});

// Watch for changes to clientId and update local storage
watch(clientId, (newClientId) => {
    if (newClientId) {
        console.log('New clientId:', newClientId);
        localStorage.setItem('client-id', newClientId);
    }
});


function clearForm() {
    product.value = {
        serialNr: '',
        model: '',
        purchaseDate: '',
        installationDate: '',
        address: '',
        city: '',
        zip: '',
        contact: '',
        phone: '',
        notes: '',
    };
}

async function submitProductAndStay() {
    try {
        registerProduct();

    } catch (error) {
        console.error(error);
    }
}

async function registerProduct() {
    try {
        if (!clientId.value) {
            router.push('/register-company');
        }
        validateFields()

        const payload = {
            "clientId": clientId.value,
            ...product.value,
        };
        await axios.post(backendUrl + '/api/register-product', payload);
        clearForm();

    } catch (error) {
        console.error(error);
    }
}

async function submitProduct() {
    try {
        registerProduct();
        router.push('/success/' + clientId.value);
    } catch (error) {
        console.error(error);
        alert('Error registering product.');
    }
}

async function getCompany() {
    try {
        const response = await axios.get(backendUrl + '/api/registrations/' + clientId.value);
        return response.data.companies[0];
    } catch (error) {
        console.error(error);
        return null;
    }
}
</script>