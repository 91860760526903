<!-- src/views/AllRegistrations.vue -->
<template>
    <div>
        <h3 class="border-bottom mb-3">{{ company.companyName }}</h3>
        <table>
            <tbody>
            <tr><td class="pe-4 text-muted">Org Nr</td><td>{{ company.orgNr }}</td></tr>
            <tr><td class="pe-4 text-muted">ID</td><td>{{ company.clientId }}</td></tr>
            <tr><td class="pe-4 text-muted">Address</td><td>{{ company.address }}</td></tr>
            <tr><td class="pe-4 text-muted">City</td><td>{{ company.city }}</td></tr>
            <tr><td class="pe-4 text-muted">Contact</td><td>{{ company.contact }}</td></tr>
            <tr v-if="company.notes"><td class="pe-4">Notes</td><td>{{ company.notes }}</td></tr>
            </tbody>
        </table>

        <h4 class="my-4 border-bottom">Registered Products</h4>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Serial Nr</th>
                    <th>Purchase Date</th>
                    <th>Installation Date</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Created</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="product in products" :key="product.id">
                    <td>{{ product.clientId }}</td>
                    <td>{{ product.serialNr }}</td>
                    <td>{{ product.purchaseDate }}</td>
                    <td>{{ product.installationDate }}</td>
                    <td>{{ product.address }}</td>
                    <td>{{ product.city }}</td>
                    <td>{{ product.created_at }}</td>
                    <td>{{ product.notes }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        Please contact <a href="mailto:info@ekoion.se">info@ekoion.se</a> if there's any issues with your registrations.
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
const backendUrl = process.env.VUE_APP_BACKEND_URL;

const router = useRouter();
const route = useRoute();
const company = ref({});
const products = ref([]);

let clientId = null;
if (route.params.clientId) {
    clientId = route.params.clientId;
    console.log('clientId from route', clientId);
}
else {
    clientId = localStorage.getItem('client-id');
    console.log('clientId from localStorage', clientId);

    if (!clientId) {
        router.push('/');    
    }
}

onMounted(async () => {
    try {
        const response = await axios.get(backendUrl + '/api/registrations/' + clientId);
        console.log('response', response.data);
        company.value = response.data.companies[0] ?? {};
        products.value = response.data.products;
    } catch (error) {
        console.error(error);
    }
});


</script>

<style scoped>
.table {
    margin-top: 20px;
}
</style>