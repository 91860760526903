<!-- src/views/Home.vue -->
<template>
    <div class="container text-center">
        <div class="my-4">
            <h2>Product Registration</h2>
            <div class="logo my-3">
                <img style="width:100%;max-width: 400px;" class="img-responsive" src="EKOion_RGB.png" alt="logo" />
            </div>
            <p>Register your EKOion products and get a free beer.</p>
            <router-link class="btn btn-lg btn-primary" to="/register-company">Register Company</router-link>
        </div>
        
        <div class="row justify-content-around" style="margin-top: 6em;">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="card border-0">
                    <div class="border-bottom">
                        <h5>Already have an ID?</h5>
                    </div>
                    <div class="card-body">
                        <label for="clientId" class="form-label mb-0">Enter your ID</label>
                        <input type="text" v-model="clientId" placeholder="XXX-XXX-XXX" class="form-control" />
                        <input type="submit" @click="submitClientId" value="Register Product" class="btn btn-outline-primary my-3" />
                    </div>
                    <div class="alert alert-danger m-2" v-if="error">
                        {{ error }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
import { isIDValid } from '../utils';
const backendUrl = process.env.VUE_APP_BACKEND_URL;
const emit = defineEmits(['companyFound'])

const router = useRouter()
const error = ref('');
const clientId = ref('');


onMounted(() => {

    console.log('localStorage.getItem(clientId)', localStorage.getItem('client-id'));
    if (localStorage.getItem('client-id')) {
        clientId.value = localStorage.getItem('client-id');
    }

    
    
});

watch(clientId, (newVal) => {
    clientId.value = clientId.value.toUpperCase();
    clientId.value = clientId.value.trim();
    localStorage.setItem('client-id', newVal);
});

const submitClientId = async () => {
    try {
        if (!isIDValid(clientId.value)) {
            error.value = 'Please enter a valid ID.';
            return;
        }
        console.log('clientId', clientId.value);

        localStorage.setItem('client-id', clientId.value);
        

        console.log('clientId from localStorage', localStorage.getItem('client-id'));

        const response = await axios.get(backendUrl + '/api/registrations/' + clientId.value);
        const companies = response.data.companies;
        if (companies.length > 0) {
            emit('companyFound');
            router.push('/register-product'); 
        } else {
            error.value = 'Coult not find any registrations with that ID.';
        }
    } catch (error) {
        console.error(error);
        error.value = 'Error fetching registrations.';
    }
};

</script>
