<template>
    <div>
        <h1>Test</h1>
        <p>
            Testing localStorage, saving seems fine but when refreshing the page, the value is not loaded. The app is removing the clientId at some point.
        </p>
        <input type="text" v-model="clientId" placeholder="Enter your ID" class="form-control" />
        <div class="btn btn-primary" @click="submitclientId">Submit</div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
const clientId = ref('');

onMounted(() => {
    if (localStorage.getItem('clientId')) {
        clientId.value = localStorage.getItem('clientId');
    }
});

onBeforeUnmount(() => {
    var tmp = localStorage.getItem('clientId');
    console.log('localStorage.getItem(clientId)', tmp);

});

function submitclientId() {
    localStorage.setItem('clientId', clientId.value);
}

</script>