<!-- src/views/AllRegistrations.vue -->
<template>
    <div>
      <h3 class="border-bottom">All Registered Companies</h3>
      <table class="table table-striped radius-0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Org Nr</th>
            <th>Company Name</th>
            <th>Address</th>
            <th>City</th>
            <th>Contact</th>
            <th>Created</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies" :key="company.id">
            <td>{{ company.clientId }}</td>
            <td>{{ company.orgNr }}</td>
            <td><a :href="'/my-registrations/' + company.clientId">{{ company.companyName }}</a></td>
            <td>{{ company.address }}</td>
            <td>{{ company.city }}</td>
            <td>{{ company.contact }}</td>
            <td>{{ company.created_at }}</td>
            <td>{{ company.notes }}</td>
          </tr>
        </tbody>
      </table>
      <!--
      <h3 class="mt-5 border-bottom">All Registered Products</h3>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Serial Nr</th>
            <th>Purchase Date</th>
            <th>Installation Date</th>
            <th>Address</th>
            <th>City</th>
            <th>Created</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.clientId }}</td>
            <td>{{ product.serialNr }}</td>
            <td>{{ product.purchaseDate }}</td>
            <td>{{ product.installationDate }}</td>
            <td>{{ product.address }}</td>
            <td>{{ product.city }}</td>
            <td>{{ product.created_at }}</td>
            <td>{{ product.notes }}</td>
          </tr>
        </tbody>
      </table>
      -->
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const backendUrl = process.env.VUE_APP_BACKEND_URL;

  export default {
    name: 'AllRegistrations',
    data() {
      return {
        companies: [],
        products: [],
      };
    },
    created() {
      this.fetchRegistrations();
    },
    methods: {
      async fetchRegistrations() {
        try {
          const response = await axios.get(backendUrl + '/api/registrations');
          this.companies = response.data.companies;
          this.products = response.data.products;
        } catch (error) {
          console.error(error);
          alert('Error fetching registrations.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .table {
    margin-top: 20px;
  }
  </style>
  